
.p {
    &-1 {
        padding: 0.2 * $grid-gutter;
    }
    &-2 {
        padding: 0.5 * $grid-gutter;
    }
    &-3 {
        padding: 1 * $grid-gutter;
    }
    &-4 {
        padding: 1.5 * $grid-gutter;
    }
    &-5 {
        padding: 2 * $grid-gutter;
    }

    &l {
        &-1 {
            padding-left: 0.2 * $grid-gutter;
        }
        &-2 {
            padding-left: 0.5 * $grid-gutter;
        }
        &-3 {
            padding-left: 1 * $grid-gutter;
        }
        &-4 {
            padding-left: 1.5 * $grid-gutter;
        }
        &-5 {
            padding-left: 2 * $grid-gutter;
        }
    }

    &r {
        &-1 {
            padding-right: 0.2 * $grid-gutter;
        }
        &-2 {
            padding-right: 0.5 * $grid-gutter;
        }
        &-3 {
            padding-right: 1 * $grid-gutter;
        }
        &-4 {
            padding-right: 1.5 * $grid-gutter;
        }
        &-5 {
            padding-right: 2 * $grid-gutter;
        }
    }

    &t {
        &-1 {
            padding-top: 0.2 * $grid-gutter;
        }
        &-2 {
            padding-top: 0.5 * $grid-gutter;
        }
        &-3 {
            padding-top: 1 * $grid-gutter;
        }
        &-4 {
            padding-top: 1.5 * $grid-gutter;
        }
        &-5 {
            padding-top: 2 * $grid-gutter;
        }
    }

    &b {
        &-1 {
            padding-bottom: 0.2 * $grid-gutter;
        }
        &-2 {
            padding-bottom: 0.5 * $grid-gutter;
        }
        &-3 {
            padding-bottom: 1 * $grid-gutter;
        }
        &-4 {
            padding-bottom: 1.5 * $grid-gutter;
        }
        &-5 {
            padding-bottom: 2 * $grid-gutter;
        }
    }
}

.m {
    &-1 {
        margin: 0.2 * $grid-gutter;
    }
    &-2 {
        margin: 0.5 * $grid-gutter;
    }
    &-3 {
        margin: 1 * $grid-gutter;
    }
    &-4 {
        margin: 1.5 * $grid-gutter;
    }
    &-5 {
        margin: 2 * $grid-gutter;
    }

    &l {
        &-1 {
            margin-left: 0.2 * $grid-gutter;
        }
        &-2 {
            margin-left: 0.5 * $grid-gutter;
        }
        &-3 {
            margin-left: 1 * $grid-gutter;
        }
        &-4 {
            margin-left: 1.5 * $grid-gutter;
        }
        &-5 {
            margin-left: 2 * $grid-gutter;
        }
    }

    &r {
        &-1 {
            margin-right: 0.2 * $grid-gutter;
        }
        &-2 {
            margin-right: 0.5 * $grid-gutter;
        }
        &-3 {
            margin-right: 1 * $grid-gutter;
        }
        &-4 {
            margin-right: 1.5 * $grid-gutter;
        }
        &-5 {
            margin-right: 2 * $grid-gutter;
        }
    }

    &t {
        &-1 {
            margin-top: 0.2 * $grid-gutter;
        }
        &-2 {
            margin-top: 0.5 * $grid-gutter;
        }
        &-3 {
            margin-top: 1 * $grid-gutter;
        }
        &-4 {
            margin-top: 1.5 * $grid-gutter;
        }
        &-5 {
            margin-top: 2 * $grid-gutter;
        }
    }

    &b {
        &-1 {
            margin-bottom: 0.2 * $grid-gutter;
        }
        &-2 {
            margin-bottom: 0.5 * $grid-gutter;
        }
        &-3 {
            margin-bottom: 1 * $grid-gutter;
        }
        &-4 {
            margin-bottom: 1.5 * $grid-gutter;
        }
        &-5 {
            margin-bottom: 2 * $grid-gutter;
        }
    }
}

.position {
    &--absolute {
        position: absolute;
    }
}
