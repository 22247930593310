.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  perspective-origin: 50% 50%;
}

.fullSize {
  flex: 1;
}
